import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { EventService } from '../../service/event.service';
import { formatDate, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { EventDTO } from '../../../../models/EventDTO';


registerLocaleData(localeFr, 'fr-FR');


@Component({
  selector: 'app-gestion-evenement',
  templateUrl: './gestion-evenement.component.html',
  styleUrl: './gestion-evenement.component.css',
})
export class GestionEvenementComponent implements OnInit {
  currentDate = new Date();
  currentMonth = '';
  currentYear = 0;
  selectedDate = new Date();
  daysInMonth: any[] = [];
  weekDays = ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'];
  selectedDayEvents: any[] = [];
  isAddEventVisible = false;
  inputDate = '';
  selectedDayName: string = ''; // Nom du jour sélectionné
  selectedFullDate: string = ''; // Date complète formatée en français
  private events: { [key: string]: any[] } = {}; // Événements par date
  imagePreviewUrl: string | null = null;
  selectedEvent: EventDTO | null = null; // Holds the selected event details

  

  newEvent = {
    name: '',
    description: '',
    timeFrom: '',
    dateEvent: '',
  };

  constructor(
    private notification: NzNotificationService,
    private eventService : EventService,
    
  ) { }

  ngOnInit() {
    this.updateCalendar();
    this.updateSelectedDateInfo();
  }

  updateCalendar() {
    const firstDay = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1);
    const lastDay = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0);

    this.currentMonth = firstDay.toLocaleString('fr-FR', { month: 'long' });
    this.currentYear = firstDay.getFullYear();

    const prevMonthDays = new Date(firstDay.getFullYear(), firstDay.getMonth(), 0).getDate();
    const daysInCurrentMonth = lastDay.getDate();
    const firstDayIndex = firstDay.getDay();

    const days = [];

    // Jours du mois précédent
    for (let i = firstDayIndex - 1; i >= 0; i--) {
      days.push({
        date: prevMonthDays - i,
        isPrevMonth: true,
        isToday: false,
        hasEvent: false,
      });
    }

    // Jours du mois courant
    for (let i = 1; i <= daysInCurrentMonth; i++) {
      const isToday = this.isSameDay(new Date(), new Date(this.currentYear, this.currentDate.getMonth(), i));
      days.push({
        date: i,
        isPrevMonth: false,
        isNextMonth: false,
        isToday,
        hasEvent: false,
      });
    }

    // Jours du mois suivant
    const remainingDays = 7 - (days.length % 7);
    for (let i = 1; i <= remainingDays; i++) {
      days.push({
        date: i,
        isNextMonth: true,
        isToday: false,
        hasEvent: false,
      });
    }

    this.daysInMonth = days;
  }

  updateSelectedDateInfo(): void {
    const options: Intl.DateTimeFormatOptions = { 
      weekday: 'long', 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    };
    this.selectedDayName = this.selectedDate.toLocaleDateString('fr-FR', { weekday: 'long' });
    this.selectedFullDate = this.selectedDate.toLocaleDateString('fr-FR', options);
  }

  isSameDay(d1: Date, d2: Date) {
    return d1.getDate() === d2.getDate() && d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear();
  }

  goToPreviousMonth() {
    this.currentDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() - 1, 1);
    this.updateCalendar();
  }

  goToNextMonth() {
    this.currentDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 1);
    this.updateCalendar();
  }

  goToToday() {
    this.currentDate = new Date();
    this.updateCalendar();
  }

  goToDate() {
    const [month, year] = this.inputDate.split('/').map((v) => +v);
    if (!isNaN(month) && !isNaN(year)) {
      this.currentDate = new Date(year, month - 1, 1);
      this.updateCalendar();
    }
  }

  selectDay(day: any) {
    this.selectedDate = new Date(this.currentYear, this.currentDate.getMonth(), day.date);
    this.updateSelectedDateInfo(); // Mettre à jour les informations de date sélectionnée
    this.isAddEventVisible = true; // Show the add event popup

    // Charger les événements associés à ce jour
    const selectedDateKey = this.selectedDate.toISOString().split('T')[0]; // Clé sous format yyyy-MM-dd
    this.selectedDayEvents = this.events[selectedDateKey] || [];
  }
  

  selectEvent(eventId: number) {
    this.fetchEventDetails(eventId);
  }

  toggleAddEvent() {
    this.isAddEventVisible = !this.isAddEventVisible;
  }


  addEvent() {
    if (this.newEvent.name && this.newEvent.timeFrom) {
      const { name, timeFrom } = this.newEvent;
      const formattedDate = this.selectedDate.toISOString().split('T')[0];
  
      this.eventService.createEvenement(
        name,
        this.newEvent.description,
        1, // User ID (exemple)
        formattedDate,
        this.selectedImage ?? undefined
      ).subscribe({
        next: (event) => {
          console.log('Événement créé:', event);
  
          // Ajouter l'événement à la liste locale
          this.selectedDayEvents.push(event);
  
          // Mettre à jour la liste des événements globaux
          if (!this.events[formattedDate]) {
            this.events[formattedDate] = [];
          }
          this.events[formattedDate].push(event);
  
          // Ajouter l'indicateur visuel au jour correspondant
          const dayIndex = this.daysInMonth.findIndex(day =>
            !day.isPrevMonth &&
            !day.isNextMonth &&
            this.isSameDay(this.selectedDate, new Date(this.currentYear, this.currentDate.getMonth(), day.date))
          );
          if (dayIndex !== -1) {
            this.daysInMonth[dayIndex].hasEvent = true; // Assurez-vous que `hasEvent` est défini sur `true`
          }
  
          // Réinitialiser le formulaire
          this.resetForm();
        },
        error: (err) => {
          console.error('Erreur lors de la création de l’événement:', err);
        },
      });
    }
  }
  
  
  
  
  resetForm() {
    this.newEvent = { name: '', timeFrom: '', description: '', dateEvent: '' };
    this.isAddEventVisible = false;
    this.selectedImage = null;
    this.imagePreviewUrl = null;
  }

  fetchEventDetails(eventId: number): void {
    this.eventService.getEvenementById(eventId).subscribe({
      next: (event) => {
        this.selectedEvent = event; // Stocker les détails de l'événement
      },
      error: (err) => {
        console.error('Erreur lors de la récupération des détails de l’événement:', err);
      },
    });
  }
  
  
  
  
  
  selectedImage: File | null = null;

  onImageSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.selectedImage = input.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.imagePreviewUrl = reader.result as string;
      };
      reader.readAsDataURL(this.selectedImage);
    }
  }
  
}