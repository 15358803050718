<head>
    <meta name="keywords" content="calendrier, événements, rappels, javascript, html, css, codage open source" />
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css"
        integrity="sha512-xh6O/CkQoPOWDdYTDqeRdPCVd1SpvCA9XXcUnZS2FmJNp1coAFzvtCN9BmamE+4aHK8yyUHUSCcJHgXloTyT2A=="
        crossorigin="anonymous" referrerpolicy="no-referrer" />
  </head>
  
  <div class="container">
    <div class="left">
        <div class="calendar">
            <div class="month">
                <i class="fas fa-angle-left prev" (click)="goToPreviousMonth()"></i>
                <div class="date">{{ currentMonth }} {{ currentYear }}</div>
                <i class="fas fa-angle-right next" (click)="goToNextMonth()"></i>
            </div>
            <div class="weekdays">
                <div *ngFor="let day of weekDays">{{ day }}</div>
            </div>
            <div class="days">
              <div
              *ngFor="let day of daysInMonth; let i = index"
              class="day"
              [class.prev-date]="day.isPrevMonth"
              [class.next-date]="day.isNextMonth"
              [class.active]="day.isToday"
              [class.event]="day.hasEvent" 
              (click)="selectDay(day)">
              {{ day.date }}
            </div>
            
            </div>
            <div class="goto-today">
                <div class="goto">
                    <input type="text" placeholder="mm/aaaa" class="date-input" [(ngModel)]="inputDate" />
                    <button class="goto-btn" (click)="goToDate()">Aller</button>
                </div>
                <button class="today-btn" (click)="goToToday()">Aujourd'hui</button>
            </div>
        </div>
    </div>
    <br>
    <div class="right">
        <div class="today-date">
            <div class="event-day">{{ selectedDayName }}</div>
            <div class="event-date">{{ selectedFullDate }}</div>
        </div>
        <br>
        <br>
        <div class="events">
          
        
          <!-- Liste des événements si aucun événement n'est sélectionné -->
          <div *ngIf="!selectedEvent">
            <div *ngFor="let event of selectedDayEvents" class="event" (click)="selectEvent(event.id)">
              <div class="title">
                <i class="fas fa-circle"></i>
                <div class="event-title">{{ event.titre }}</div>
              </div>
              <div class="event-time">
                {{ event.dateEvent | date: 'shortTime':'fr-FR' }}
              </div>
            </div>
            <div *ngIf="selectedDayEvents.length === 0" class="no-event">
              Aucun événement pour ce jour.
            </div>
          </div>
        </div>
        
        <div class="add-event-wrapper" [class.active]="isAddEventVisible">
            <div class="add-event-header">
                <div class="title">Ajouter un événement</div>
                <i class="fas fa-times close" (click)="toggleAddEvent()"></i>
            </div>
            <div class="add-event-body">
                <div class="add-event-input">
                    <input type="text" placeholder="Nom de l'événement" class="event-name"
                        [(ngModel)]="newEvent.name" />
                </div>
                <div class="add-event-input">
                    <input type="text" placeholder="Description" class="event-name" [(ngModel)]="newEvent.description" />
                </div>
                <div class="add-event-input">
                    <input type="text" placeholder="Heure de début" class="event-time-from"
                        [(ngModel)]="newEvent.timeFrom" />
                </div>
                <div class="add-event-input">
                    <label for="event-image" class="event-image-label">
                        <i class="fas fa-image"></i> Ajouter une image
                    </label>
                    <input type="file" id="event-image" class="event-image-input" (change)="onImageSelected($event)" />
                </div>
                <div class="image-preview" *ngIf="selectedImage">
                    <img [src]="imagePreviewUrl" alt="Aperçu de l'image" />
                  </div>
  
            </div>
            <div class="add-event-footer">
                <button class="add-event-btn" (click)="addEvent()">Ajouter</button>
            </div>
        </div>
    </div>
    <button class="add-event" (click)="toggleAddEvent()">
        <i class="fas fa-plus"></i>
    </button>
  </div>