import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '../../../../environments/environement';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EventDTO } from '../../../models/EventDTO';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  baseUrl = environment.apiUrl + '/api/evenements';

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { } 
  private getAuthHeaders(): { [header: string]: string } {
    if (isPlatformBrowser(this.platformId)) {
      const token = localStorage.getItem('presence');
      return token ? { Authorization: `Bearer ${token}` } : {};
    }
    return {};
  }


 // Créer un événement
 createEvenement(
  titre: string,
  description: string,
  userId: number,
  dateEvent?: string,
  photo?: File
): Observable<EventDTO> {
  const formData = new FormData();
  formData.append('titre', titre);
  formData.append('description', description);
  if (dateEvent) formData.append('dateEvent', dateEvent);
  if (photo) formData.append('photo', photo);

  return this.http.post<EventDTO>(`${this.baseUrl}/create/${userId}`, formData, {
    headers: this.getAuthHeaders(),
  });
}

// Mettre à jour un événement
updateEvenement(
  id: number,
  titre: string,
  description: string,
  dateEvent?: string,
  photo?: File
): Observable<EventDTO> {
  const formData = new FormData();
  formData.append('titre', titre);
  formData.append('description', description);
  if (dateEvent) formData.append('dateEvent', dateEvent);
  if (photo) formData.append('photo', photo);

  return this.http.put<EventDTO>(`${this.baseUrl}/update/${id}`, formData, {
    headers: this.getAuthHeaders(),
  });
}

// Supprimer un événement
deleteEvenement(id: number): Observable<string> {
  return this.http.delete<string>(`${this.baseUrl}/delete/${id}`, {
    headers: this.getAuthHeaders(),
  });
}

// Obtenir un événement par ID
getEvenementById(id: number): Observable<EventDTO> {
  return this.http.get<EventDTO>(`${this.baseUrl}/${id}`, {
    headers: this.getAuthHeaders(),
  });
}

// Obtenir les événements après aujourd'hui
getEvenementsAfterToday(): Observable<EventDTO[]> {
  return this.http.get<EventDTO[]>(`${this.baseUrl}/after-today`, {
    headers: this.getAuthHeaders(),
  });
}
}